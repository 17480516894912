import { IPrivilegeFormDataModel } from "@helpers/interfaces/privilege-viewer";

export interface IPrivilegingFormErrors {
  organizationId: string;
  // providerDob: string;
  providerLastName: string;
  providerFirstName: string;
}

export const validatePrvilegeViewerForm = (values: IPrivilegeFormDataModel) => {
  const errors = {} as IPrivilegingFormErrors;

  if (!values.organizationId) {
    errors.organizationId = "Required";
  }

  // if (!values.providerDob) {
  //   errors.providerDob = "Required";
  // }

  if (!values.providerLastName) {
    errors.providerLastName = "Required";
  }

  if (!values.providerFirstName) {
    errors.providerLastName = "Required";
  }

  return errors;
};
