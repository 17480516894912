import applicationTableColumnsTypes from "@constants/application-table-columns-types";

import { IApplicationTableColumn } from "@helpers/uiInterfaces/common";

const credTrackingListColumns: Array<IApplicationTableColumn> = [
  {
    id: "jobNo",
    label: "Instance",
    type: applicationTableColumnsTypes.STRING,
  },
  {
    id: "credTypeValue",
    label: "Cred Type",
    type: applicationTableColumnsTypes.CUSTOM,
  },
  {
    id: "processingTypeValue",
    label: "Processing Type",
    type: applicationTableColumnsTypes.CUSTOM,
  },
  {
    id: "jobStatus",
    label: "Status",
    type: applicationTableColumnsTypes.STRING,
  },
  {
    id: "organization_name",
    label: "Organization",
    type: applicationTableColumnsTypes.MULTIPLE_VALUES,
  },
  {
    id: "CredentialingFromDate",
    label: "From Date",
    type: applicationTableColumnsTypes.DATE,
  },
  {
    id: "CredentialingToDate",
    label: "To Date",
    type: applicationTableColumnsTypes.DATE,
  },
];

export default credTrackingListColumns;
