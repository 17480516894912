const stepperSteps = [
  {
    label: "In Active",
    value: "In Active",
    description:
      "Providers Credentialing Instance is marked InActive. Please open a support ticket on this page if you have any specific questions with regards to this instance",
  },
  {
    label: "Job Created",
    value: "Job Created",
    description:
      "Credentialing Request has been accepted and a credentialer is being assigned",
  },
  {
    label: "App Sent",
    value: "App Sent",
    description:
      "A credentialer has been assigned and the application has been sent. Please complete the application",
  },
  {
    label: "App Received",
    value: "App Received",
    description:
      "App Received from the provider. Please wait for a credentialer to review your submitted application",
  },
  {
    label: "App In Review",
    value: "App In Review",
    description:
      "A credentialer is reviewing the application and will contact you if they find any missing information",
  },
  {
    label: "App Resent",
    value: "App Resent",
    description:
      "Please complete the incomplete application by logging into the compliance watchdog provider portal",
  },
  {
    label: "App Complete",
    value: "App Complete (Clean App)",
    description:
      "Application is verified and marked as Clean Application. At this point, you do not have do anything, please just wait for credentialing to be completed",
  },
  {
    label: "PSV In Process",
    value: "PSV In Process",
    description: "Primary Source Verification in Process",
  },
  {
    label: "PSV In Review",
    value: "PSV In Review",
    description: "Primary Source Verification in Review",
  },
  {
    label: "Credentialing Completed",
    value: "Credentialing Completed",
    description:
      "Credentialing is completed and the file is submitted to the client’s board for review",
  },
  {
    label: "Credentialing Approved",
    value: "Credentialing Approved",
    description:
      "Credentialing is approved by the board and the provider should have received a board approval letter",
  },
];

export default stepperSteps;
