import ContentHeader from "@common/ContentHeader";
import { useAppSelector } from "@hooks/index";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./PrivilegeViewer.module.scss";
import { IPrivilegeFormDataModel } from "@helpers/interfaces/privilege-viewer";
import { getPrivilegeList } from "@services/privilege-viewer";
import FormInputTextControl from "@common/form-controls/FormInputTextControl";
import FormDateInputControl from "@common/form-controls/FormDateInputControl";
import privilegeViewerListColumns from "@constants/json-data/table-columns/privilege-viewer/privilegeViewerListColumns";
import PaginatedTable from "@common/paginated-table/PaginatedTable";
import NoContentSection from "@common/no-content-section/NoContentSection";
import Spinner from "@common/spinner/Spinner";
import { setDefaultPrivilegeFormData } from "@helpers/configMiddleware/privilege-viewer";
import { useFormik } from "formik";
import { validatePrvilegeViewerForm } from "./utilities";
import { privilegeActions } from "@features/privilege-viewer";
import { getOrganizationsByNetworkId } from "@services/master/master.services";
import { parseOrganizationSelectControlsData } from "@utilities/index";
import FormSelectInputControl from "@common/form-controls/FormSelectInputControl";

const PrivilegeViewer = () => {
  const [formData, setFormData] = useState<IPrivilegeFormDataModel>(
    setDefaultPrivilegeFormData()
  );

  const formik = useFormik({
    initialValues: formData as IPrivilegeFormDataModel,
    validate: validatePrvilegeViewerForm,
    onSubmit: handleSaveControlClick,
  });
  const formikValues = formik.values;
  const formikErrors = formik.errors;

  const dispatch = useDispatch<any>();
  const privilegeViewerState = useAppSelector((state) => state.privilegeViewer);
  const privilegeList = privilegeViewerState.privilegeList?.page || [];
  const organizationsObject = useAppSelector(
    (state) => state.master.organizations
  );
  const organizations = organizationsObject?.value || [];

  const loadDependencies = useCallback(async () => {
    await dispatch(getOrganizationsByNetworkId(40));
  }, [dispatch]);

  useEffect(() => {
    loadDependencies();
  }, [loadDependencies]);

  useEffect(() => {
    return () => {
      dispatch(privilegeActions.resetState());
    };
  }, [dispatch]);

  const contentHeaderAttributes = {
    title: "Privilege viewer",
    instructions: [],
  };

  async function loadPrivilegeViewerList(page: number, limit: number) {
    const payload = { ...formData, pageIndex: page, pageSize: limit };
    await dispatch(getPrivilegeList(payload));
    setFormData({
      ...formData,
      pageIndex: page,
      pageSize: limit,
    });
  }

  const handleControlChange = (name: string, value: string) => {
    const modifiedFormData = { ...formData, [name]: value };
    formik.setFieldValue(name, value);
    setFormData(modifiedFormData);
  };

  async function handleSaveControlClick() {
    loadPrivilegeViewerList(formData.pageIndex, formData.pageSize);
  }

  const renderFields = () => {
    let orgNameError = "" as string | undefined;

    if (
      formikErrors.organizationId !== "" &&
      formik.touched.organizationId === true
    ) {
      orgNameError = formikErrors.organizationId;
    }

    // let dobError = "" as string | undefined;

    // if (
    //   formikErrors.providerDob !== "" &&
    //   formik.touched.providerDob === true
    // ) {
    //   dobError = formikErrors.providerDob;
    // }

    let firstNameError = "" as string | undefined;

    if (
      formikErrors.providerFirstName !== "" &&
      formik.touched.providerFirstName === true
    ) {
      firstNameError = formikErrors.providerFirstName;
    }

    let lastNameError = "" as string | undefined;

    if (
      formikErrors.providerLastName !== "" &&
      formik.touched.providerLastName === true
    ) {
      lastNameError = formikErrors.providerLastName;
    }

    const organizationControlAttributes = {
      label: "Organization Name",
      name: "organizationId",
      required: true,
      error: orgNameError,
      value: formikValues.organizationId,
      data: parseOrganizationSelectControlsData(organizations),
      onChange: handleControlChange,
      onBlur: formik.handleBlur,
    };

    const dobControlAttributes = {
      label: "Provider DOB",
      name: "providerDob",
      // required: true,
      // error: dobError,
      value: formikValues.providerDob,
      onChange(value: string) {
        handleControlChange("providerDob", value);
      },
      // onBlur: formik.handleBlur,
    };

    const firstNameControlAttributes = {
      label: "Provider First Name",
      placeholder: "Enter First Name",
      type: "text",
      name: "providerFirstName",
      required: true,
      error: firstNameError,
      value: formikValues.providerFirstName,
      onChange(e: any) {
        handleControlChange("providerFirstName", e.target.value);
      },
      onBlur: formik.handleBlur,
    };

    const lastNameControlAttributes = {
      label: "Provider Last Name",
      placeholder: "Enter Last Name",
      type: "text",
      name: "providerLastName",
      required: true,
      error: lastNameError,
      value: formikValues.providerLastName,
      onChange(e: any) {
        handleControlChange("providerLastName", e.target.value);
      },
      onBlur: formik.handleBlur,
    };

    const submitControlAttributes = {
      className: "btn btnorg mt-4 w100",
      onClick() {
        formik.handleSubmit();
      },
    };

    return (
      <div className={styles.card}>
        <div className="row align-items-center">
          <div className="col-md-3 col-sm-12 col-xs-12 mb-3">
            <FormSelectInputControl {...organizationControlAttributes} />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12 mb-3">
            <FormDateInputControl {...dobControlAttributes} />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12 mb-3">
            <FormInputTextControl {...firstNameControlAttributes} />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12 mb-3">
            <FormInputTextControl {...lastNameControlAttributes} />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12 mb-3">
            <button {...submitControlAttributes}>Submit</button>
          </div>
        </div>
      </div>
    );
  };

  const renderTable = () => {
    if (!privilegeList || privilegeViewerState.loading) {
      return <Spinner />;
    }

    if (privilegeList.length === 0) {
      return <NoContentSection />;
    }

    const paginatedTableAttributes = {
      columns: privilegeViewerListColumns,
      rows: privilegeList,
      currentPage: formData.pageIndex,
      totalRecord: privilegeViewerState.privilegeList?.paging?.totalItems,
      rowsPerPage: formData.pageSize,
      onPageLimitChange(limit: number) {
        loadPrivilegeViewerList(formData.pageIndex, limit);
      },
      onPageChange(page: number) {
        loadPrivilegeViewerList(page, formData.pageSize);
      },
    };

    return <PaginatedTable {...paginatedTableAttributes} />;
  };

  return (
    <div id={styles.privilegeViewerMain}>
      <ContentHeader {...contentHeaderAttributes} />
      <div className="container">
        <h4 className={styles.gridTitle}>Privilege Viewer List</h4>
        {renderFields()}
        <div className={styles.privilegeTableHeading}>
          Privileges for:{" "}
          <span className={styles.headingValue}>
            {privilegeList.length > 0
              ? privilegeList[0].providerName
              : "--NA--"}
          </span>
        </div>
        {renderTable()}
      </div>
    </div>
  );
};

export default PrivilegeViewer;
