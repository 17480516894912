import { useCallback, useEffect, useState } from "react";
import ContentHeader from "@common/ContentHeader";
import FormDateInputControl from "@common/form-controls/FormDateInputControl";
import FormInputTextControl from "@common/form-controls/FormInputTextControl";
import styles from "./CredTracking.module.scss";
import PaginatedTable from "@common/paginated-table/PaginatedTable";
import credTrackingListColumns from "@constants/json-data/table-columns/cred-tracking/credTrackingListColumns";
import {
  getCredTrackingJobWorkflow,
  getCredTrackingList,
} from "@services/cred-tracking";
import { useDispatch } from "react-redux";
import { ICredFormDataModel } from "@helpers/interfaces/cred-tracking";
import { useAppSelector } from "@hooks/index";
import Spinner from "@common/spinner/Spinner";
import NoContentSection from "@common/no-content-section/NoContentSection";
import { parseCredTrackingData, validateCredTrackingForm } from "./utilities";
import { setDefaultCredTrackingFormData } from "@helpers/configMiddleware/cred-tracking";
import StepperComponent from "@common/stepper/StepperComponent";
import { useFormik } from "formik";
import { credTrackingActions } from "@features/cred-tracking";
import moment from "moment";

const CredTracking = () => {
  const dispatch = useDispatch<any>();
  const [formData, setFormData] = useState<ICredFormDataModel>(
    setDefaultCredTrackingFormData()
  );

  const formik = useFormik({
    initialValues: formData as ICredFormDataModel,
    validate: validateCredTrackingForm,
    onSubmit: handleSaveControlClick,
  });
  const formikValues = formik.values;
  const formikErrors = formik.errors;

  const credTrackingState = useAppSelector((state) => state.credTracking);
  const credTrackingList = credTrackingState.credTrackingList?.page || [];

  useEffect(() => {
    return () => {
      dispatch(credTrackingActions.resetState());
    };
  }, [dispatch]);

  const contentHeaderAttributes = {
    title: "Credentialing Tracking",
    instructions: [],
  };

  async function loadCredTrackingList(page: number, limit: number) {
    const payload = { ...formData, pageIndex: page, pageSize: limit };
    const res = await dispatch(getCredTrackingList(payload));
    if (res.status === 200) {
      if (res.data && res.data.page && res.data.page.length > 0) {
        loadCredTrackingJobWorkflow(res.data.page[0].credentialingTrackingId);
      }
    }
    setFormData({
      ...formData,
      pageIndex: page,
      pageSize: limit,
    });
  }

  const loadCredTrackingJobWorkflow = useCallback(
    async (credTrackingId: number) => {
      const payload = {
        credentialingTrackingId: credTrackingId,
        pageIndex: formData.pageIndex,
        pageSize: formData.pageSize,
      };
      if (credTrackingId) {
        await dispatch(getCredTrackingJobWorkflow(payload));
      }
    },
    [dispatch, formData.pageIndex, formData.pageSize]
  );

  const handleControlChange = (name: string, value: string) => {
    const modifiedFormData = { ...formData, [name]: value };
    formik.setFieldValue(name, value);
    setFormData(modifiedFormData);
  };

  async function handleSaveControlClick() {
    loadCredTrackingList(formData.pageIndex, formData.pageSize);
  }

  function getCurrentCredStatus(fromDate: string, toDate: string) {
    // Check if fromDate or toDate is "--NA--"
    if (fromDate === "--NA--" || toDate === "--NA--") {
      return "--NA--";
    }

    // Get today's date
    const today = moment();

    // Check if today's date is between fromDate and toDate
    if (today.isBetween(fromDate, toDate, null, "[]")) {
      return "Active";
    } else {
      return "--NA--";
    }
  }

  const renderCredDetails = () => {
    if (credTrackingList && credTrackingList.length > 0) {
      const fromDate = credTrackingList[0].fromDate
        ? credTrackingList[0].fromDate === "0001-01-01T00:00:00"
          ? "--NA--"
          : moment(credTrackingList[0].fromDate).format("MM/DD/YYYY")
        : "--NA--";
      const toDate = credTrackingList[0].toDate
        ? credTrackingList[0].toDate === "0001-01-01T00:00:00"
          ? "--NA--"
          : moment(credTrackingList[0].toDate).format("MM/DD/YYYY")
        : "--NA--";

      return (
        <div>
          <div className={styles.credDetailsHeading}>
            Current Credentialing Status:{" "}
            <span className={styles.headingValue}>
              {getCurrentCredStatus(fromDate, toDate)}
            </span>
          </div>
          <div>
            <div className={styles.credDetailsHeadingLabel}>
              From Date:{" "}
              <span className={styles.credDetailsHeadingValue}>{fromDate}</span>
            </div>
            <div className={styles.credDetailsHeadingLabel}>
              To Date:{" "}
              <span className={styles.credDetailsHeadingValue}>{toDate}</span>
            </div>
          </div>
        </div>
      );
    }
  };

  const renderFields = () => {
    let npiError = "" as string | undefined;

    if (
      formikErrors.providerNpi !== "" &&
      formik.touched.providerNpi === true
    ) {
      npiError = formikErrors.providerNpi;
    }

    let dobError = "" as string | undefined;

    if (
      formikErrors.providerDob !== "" &&
      formik.touched.providerDob === true
    ) {
      dobError = formikErrors.providerDob;
    }

    let lastNameError = "" as string | undefined;

    if (
      formikErrors.providerLastName !== "" &&
      formik.touched.providerLastName === true
    ) {
      lastNameError = formikErrors.providerLastName;
    }

    const npiControlAttributes = {
      label: "Provider NPI",
      placeholder: "Enter Provider NPI",
      type: "text",
      name: "providerNpi",
      required: true,
      error: npiError,
      value: formikValues.providerNpi,
      onChange(e: any) {
        handleControlChange("providerNpi", e.target.value);
      },
      onBlur: formik.handleBlur,
    };

    const dobControlAttributes = {
      label: "Provider DOB",
      name: "providerDob",
      required: true,
      error: dobError,
      value: formikValues.providerDob,
      onChange(value: string) {
        handleControlChange("providerDob", value);
      },
      onBlur: formik.handleBlur,
    };

    const lastNameControlAttributes = {
      label: "Provider Last Name",
      placeholder: "Enter Last Name",
      type: "text",
      name: "providerLastName",
      required: true,
      error: lastNameError,
      value: formikValues.providerLastName,
      onChange(e: any) {
        handleControlChange("providerLastName", e.target.value);
      },
      onBlur: formik.handleBlur,
    };

    const submitControlAttributes = {
      className: "btn btnorg mt-4 w100",
      onClick() {
        formik.handleSubmit();
      },
    };

    return (
      <div className={styles.card}>
        <div className="row align-items-center">
          <div className="col-md-3 col-sm-12 col-xs-12 mb-3">
            <FormInputTextControl {...npiControlAttributes} />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12 mb-3">
            <FormDateInputControl {...dobControlAttributes} />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12 mb-3">
            <FormInputTextControl {...lastNameControlAttributes} />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12 text-end mb-3">
            <button {...submitControlAttributes}>Submit</button>
          </div>
        </div>
      </div>
    );
  };

  const renderTable = () => {
    if (!credTrackingList || credTrackingState.loading) {
      return <Spinner />;
    }

    if (credTrackingList.length === 0) {
      return <NoContentSection />;
    }

    const pasecredTrackingData = parseCredTrackingData([credTrackingList[0]]);

    const paginatedTableAttributes = {
      columns: credTrackingListColumns,
      rows: pasecredTrackingData,
      currentPage: formData.pageIndex,
      totalRecord: credTrackingState.credTrackingList?.paging?.totalItems,
      rowsPerPage: formData.pageSize,
      onPageLimitChange(limit: number) {
        loadCredTrackingList(formData.pageIndex, limit);
      },
      onPageChange(page: number) {
        loadCredTrackingList(page, formData.pageSize);
      },
    };

    return <PaginatedTable {...paginatedTableAttributes} />;
  };

  return (
    <div id={styles.credTrackingMain}>
      <ContentHeader {...contentHeaderAttributes} />
      <div className="container">
        <h4 className={styles.gridTitle}>Credentialing Tracking List</h4>
        {renderFields()}
        {renderCredDetails()}
        <div className={styles.credDetailsHeading}>
          Credentialing Instance Currently in Process
        </div>
        {credTrackingList.length > 0 && <StepperComponent />}
        {renderTable()}
      </div>
    </div>
  );
};

export default CredTracking;
